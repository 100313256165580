import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}

// markup
const FindUsPage = () => {
  return (
    <main style={pageStyles}>
      <title>Find Us</title>
      <h1 style={headingStyles}>
        Upper Prospect - Find Us
      </h1>
      <p style={paragraphStyles}>
        Access to Upper Prospect is quite narrow and if you arrive in a vehicle much larger than a Transit van, then you may find it is too wide to get down our track. Your best option here will be to park on Nettleton Hill Road and walk down.
      </p>
      <p style={paragraphStyles}>
        It's best to use our postcode <a href="https://www.google.co.uk/maps/place/HD7%204PD">HD7 4PD</a>.
        
        This will bring you down Nettleton Hill Road, past the Pennine Manor Hotel and straight across a crossroads, where you will reach a fork, keep left onto Green Lane Terrace.
      </p>
      <StaticImage src="../images/find-us/nettleton-hill-road.jpg" alt="Fork left from Nettleton Hill Road onto Green Lane Terrace" />
      <p style={paragraphStyles}>
        At the end of Green Lane Terrace carry on straight forward down the concrete track.
      </p>
      <StaticImage src="../images/find-us/green-lane-terrace.jpg" alt="Concrete Track at the end of Green Lane Terrace"/>
      <p style={paragraphStyles}>
        Once you are down the track, take the first driveway on the left and carry on to the top of the driveway. There is plenty of space to park.
      </p>
      <StaticImage src="../images/find-us/driveway.jpg" alt="Upper Prospect Driveway" />
    </main>
  )
}

export default FindUsPage
